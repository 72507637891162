<template>
  <div>
    <el-form ref="form" :model="form" label-width="100px" style="display: flex">
      <div>
        <el-row type="flex" justify="space-between">
          <div style="width: 85%">
            <el-row>
              <!-- 消息id -->
              <el-col :span="6">
                <el-form-item :label="$t('title.platform')">
                  <el-select v-model="form.platform" multiple clearable filterable :placeholder="$t('title.platform')">
                    <el-option
                      v-for="item in platformList"
                      :key="item.id"
                      :label="item.platformCode"
                      :value="item.platformCode"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="$t('tips.Sensitivewords')">
                  <el-input v-model="form.sensitiveWord" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="$t('order.Setupby')">
                  <el-input v-model="form.createByName" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="$t('page.status')">
                  <el-select v-model="form.wordStatus" clearable filterable placeholder="请选择">
                    <el-option :label="$t('page.disable')" value="0" />
                    <el-option :label="$t('page.enabled')" value="1" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('page.createDate')">
                  <el-date-picker
                    v-model="form.date"
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    range-separator="~"
                    :start-placeholder="$t('title.starttime')"
                    :end-placeholder="$t('title.endtime')"
                    :unlink-panels="true"
                    :default-time="['00:00:00', '23:59:59']"
                  />
                </el-form-item>
              </el-col>

            </el-row>
            <!-- <el-row :span="24" class="mb-3">
              <el-col :span="24" style="display:flex;height:36px;">
                <el-button type="pa" @click="handleAssign">{{ $t('title.distribute') }}</el-button>
              </el-col>
            </el-row> -->
          </div>
          <el-row style="width: 15%">
            <el-col class="row-center">
              <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
              <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
              <el-button type="primary" @click="dialogFormVisibleWords = true">{{ $t('page.add') }}</el-button>
            </el-col>
          </el-row>
        </el-row>
      </div>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      class="mb-3"
      border
      :data="tableDatas"
      :header-cell-style="{background:'#fafafa',color:'#606266'}"
      max-height="500px"
    >
      <el-table-column
        prop="sensitiveWord"
        :label="$t('tips.Sensitivewords')"
        align="center"
        sortable
      />

      <el-table-column
        prop="platform"
        :label="$t('title.platform')"
        align="center"
        sortable
      />
      <el-table-column
        prop="createByName"
        :label="$t('page.createByName')"
        align="center"
        sortable
      />
      <el-table-column
        align="center"
        :label="$t('page.status')"
        width="150"
      >
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.wordStatus"
            inactive-color="#eee"
            active-color="#13ce66"
            :active-value="1"
            :inactive-value="0"
            @change="handleSwitch(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        :label="$t('page.createTime')"
        align="center"
        sortable
      />
    </el-table>
    <el-pagination
      :current-page="pager.current"
      :page-sizes="[10,20, 50, 100, 200, 300]"
      :page-size="pager.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pager.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <el-dialog :title="$t('tips.Addsensitivewords')" :visible.sync="dialogFormVisibleWords">
      <el-form :model="sensitiveWordForm">
        <el-form-item :label="$t('title.platform')">
          <el-select v-model="sensitiveWordForm.platform" multiple clearable filterable :placeholder="$t('title.platform')">
            <el-option
              v-for="item in platformList"
              :key="item.id"
              :label="item.platformCode"
              :value="item.platformCode"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('tips.Sensitivewords')">
          <el-input v-model="sensitiveWordForm.sensitiveWord" type="textarea" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleWords = false">{{ $t('title.cancel') }}</el-button>
        <el-button :loading="AssignBuLoding" type="primary" @click="submitSensitiveWord">{{ $t('title.sure') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '@/mixin/oms-mixin'
import { queryTerraceList } from '@/api/omsorder'
import { getSensitiveWordsByPage, batchInsertSensitiveWords, updateBatchSensitiveWords } from '@/api/service-management'
export default {
  mixins: [mixin],
  data() {
    return {
      pager: {
        current: 1,
        size: 10,
        total: 0
      },
      dialogFormVisibleWords: false,
      TableLoading: false,
      tableDatas: [],
      platformList: [], // 平台条件查询
      form: {
        platform: '',
        createByName: '',
        sensitiveWord: '',
        wordStatus: '',
        date: []
      },
      buOption: [],
      sensitiveWordForm: {
        sensitiveWord: '',
        platform: ''
      },
      dialogFormVisibleAssign: false,
      AssignBuLoding: false

    }
  },

  computed: {
    queryParameter() {
      const [createStartTime = '', createEndTime = ''] = this.form.date || []

      return Object.assign({}, this.form, this.pager, { createStartTime, createEndTime, platform: this.form.platform ? this.form.platform.join(',') : this.form.platform })
    }
  },

  mounted() {
    this._getSensitiveWordsByPage()
    this._queryTerraceList()
  },
  methods: {
    // 获取平台
    async _queryTerraceList() {
      const { datas } = await queryTerraceList()
      this.platformList = datas
      this.platformList = this.platformList.filter((item) => {
        return item.platformCode === 'amazon' ||
        item.platformCode === 'ebay' ||
        item.platformCode === 'walmart' ||
        item.platformCode === 'Shopify' ||
        item.platformCode === 'standwebsite' ||
        // sb正式测试不一样
        item.platformCode === 'Tik Tok' ||
        item.platformCode === 'TikTok'
      })
    },
    // 点击查询获取信息
    queryClick() {
      this.pager.current = 1
      this._getSensitiveWordsByPage(this.queryParameter)
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.form = {}
      this._getSensitiveWordsByPage(1)
    },
    // 默认查询
    async _getSensitiveWordsByPage(params) {
      try {
        this.TableLoading = true
        const { datas } = await getSensitiveWordsByPage(this.queryParameter)
        this.tableDatas = datas.records
        this.pager = datas.pager
      } finally {
        this.TableLoading = false
      }
    },

    handleSwitch(row) {
      const ids = []
      ids.push(row.id)
      const status = row.wordStatus
      this._updateBatchSensitiveWords(ids, status)
    },
    async _updateBatchSensitiveWords(ids, status) {
      try {
        this.loading = true
        const { code, msg } = await updateBatchSensitiveWords(ids, status)
        code === 0 ? this.$message.success(msg) : this.$message.error(msg)
        this._getSensitiveWordsByPage(this.queryParameter)
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    handleSizeChange(val) {
      this.pager.size = val
      this._getSensitiveWordsByPage(this.queryParameter)
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._getSensitiveWordsByPage(this.queryParameter)
    },

    submitSensitiveWord() {
      const { sensitiveWord, platform } = this.sensitiveWordForm

      this._batchInsertSensitiveWords({ sensitiveWord: sensitiveWord, platform: platform ? platform.join(',') : platform })
    },
    // 更新状态
    async _batchInsertSensitiveWords(data) {
      try {
        this.AssignBuLoding = true
        const { code, msg } = await batchInsertSensitiveWords(data)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
        }
        this._getSensitiveWordsByPage(this.queryParameter)
      } finally {
        this.AssignBuLoding = false
        this.dialogFormVisibleWords = false
        Object.assign(this.sensitiveWordForm, this.$options.data.call(this).sensitiveWordForm)
      }
    }

  }
}
</script>

<style scoped lang="scss">
.mark,
.count {
  color: red;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

/deep/.el-tabs__header {
  margin: 0 !important;
}
.Row-Input {
  height: 38px;
  overflow: hidden;
}
.show-row {
  height: auto;
}
</style>
